var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c(
                "form",
                {
                  attrs: { id: "pagelayout" },
                  on: { submit: _vm.handleFormSubmit },
                },
                [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column is-two-thirds-desktop" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "block" }, [
                        _c("div", [
                          _vm.fileError.length !== 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "padding-left": "1rem",
                                  },
                                },
                                _vm._l(_vm.fileError, function (error) {
                                  return _c(
                                    "div",
                                    {
                                      key: error,
                                      staticClass: "has-text-danger",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "min-width": "100%!important",
                                            margin: ".25rem 0rem",
                                          },
                                        },
                                        [_vm._v("Error: " + _vm._s(error))]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm.groups.length > 0
                            ? _c(
                                "div",
                                {
                                  ref: "filePondWrapper",
                                  class: [
                                    "filePondWrapper",
                                    _vm.$refs.filePond &&
                                    _vm.$refs.filePond.getFiles().length < 1
                                      ? "has-no-files"
                                      : "",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.openFileBrowser.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("file-pond", {
                                    ref: "filePond",
                                    attrs: {
                                      name: "file",
                                      "label-idle": _vm.filePondLabel,
                                      "allow-multiple": "true",
                                      "accepted-file-types":
                                        "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                      files: _vm.input.files,
                                      dropOnElement: false,
                                      dropOnPage: true,
                                      credits: [],
                                    },
                                    on: {
                                      addfilestart: _vm.addfile,
                                      removefile: _vm.removefile,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "column is-one-third-desktop" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "block" }, [
                        _c("div", [
                          _c("div", { staticClass: "field" }, [
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("Checkbox", {
                                  attrs: {
                                    isChecked: _vm.input.shouldSendNotification,
                                    label: "Send email notification",
                                  },
                                  on: { isChecked: _vm.handleIsChecked },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.input.shouldSendNotification && _vm.emailTemplates
                            ? _c("div", { staticClass: "field" }, [
                                _vm._m(2),
                                _c(
                                  "div",
                                  { staticClass: "control" },
                                  [
                                    _c("Select", {
                                      ref: "emailTemplate",
                                      staticStyle: {
                                        "max-width": "75%",
                                        width: "100%",
                                      },
                                      attrs: {
                                        options: _vm.emailTemplates,
                                        identifier: "emailTemplate",
                                      },
                                      on: { onchange: _vm.handleSelectChange },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.length > 0,
                                expression: "errors.length>0",
                              },
                            ],
                            staticClass:
                              "has-background-warning has-text-white",
                            staticStyle: {
                              padding: "0.5rem 1rem 1rem 1rem",
                              "margin-top": "1rem",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "has-text-weight-bold" },
                              [_vm._v("Error")]
                            ),
                            _c(
                              "ul",
                              { attrs: { id: "example-1" } },
                              _vm._l(_vm.errors, function (err, idx) {
                                return _c(
                                  "li",
                                  {
                                    key: err + idx,
                                    staticStyle: { "margin-top": "5px" },
                                  },
                                  [_vm._v(_vm._s(err))]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: { "padding-top": "20px" },
                      attrs: { id: "buttongroup" },
                    },
                    [
                      _c("div", { staticClass: "field is-grouped" }, [
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button is-accent has-text-white",
                              attrs: {
                                disabled:
                                  _vm.processing ||
                                  _vm.input.files.length < 1 ||
                                  !_vm.$hasPermissions(
                                    _vm.clientSession,
                                    ["DOCUMENT_CENTER"],
                                    2
                                  ),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleFormSubmit.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              !_vm.processing
                                ? _c("span", [_vm._v("Save")])
                                : _vm._e(),
                              _vm.processing
                                ? _c("span", [_c("spin-loader")], 1)
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button is-light",
                              on: {
                                click: function ($event) {
                                  _vm.resetForm()
                                  _vm.$router.go(-1)
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Documents"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Documents"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Email Template "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }